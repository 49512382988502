import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { decryptString, extractShareIdByKey } from "../../utils/decryptText";
import { withoutAuth } from "../../config/axios";
import { clientPath } from "../../config/key";
import {
  DiaryDiamondColors,
  DiaryDiamondShapes,
} from "../../utils/dairyDiamondsDetail";
import Loader from "../loader/loader";
import { Helmet } from "react-helmet";

const ShareTrueFacePage = () => {
  const { id } = useParams();
  const [reportData, setReportData] = useState({});
  const location = useLocation();
  const [loader, setLoader] = useState(true);
  const [viewOnce, setViewOnce] = useState(false);
  const [imgScaleVal, setImgScaleVal] = useState(1);


  useEffect(() => {
    async function fetchData(_id) {
      const { postId } = await getReportById(_id);
      console.log("postId", postId);

      // await sharePageLoaded(postId, createdBy);
    }
    const params = location.search;
    if (params?.includes("?id")) {
      const decData = decryptString(id);
      const shareIdvalue = extractShareIdByKey(decData, "Id");
      fetchData(shareIdvalue);
    } else {
      // Check for the updated link
      fetchData(id);
    }
    // eslint-disable-next-line
  }, [id]);


  const getReportById = async (id) => {
    try {
      setLoader(true);
      const response = await withoutAuth().post(
        "truefaceup/get-faceup-report-by-id",
        {
          id,
        }
      );
      const sizeValueInit =
      response.data?.getReport?.payload?.length / response.data?.getReport?.payload?.width || 0;
    let sizeValue =
      sizeValueInit.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] || 0;
    if (
      response.data?.getReport?.payload?.shape === "Cushion" ||
      response.data?.getReport?.payload?.shape === "Radiant"
    ) {
      if (sizeValue <= 1.05) {
        setImgScaleVal(1);
      } else if (sizeValue >= 1.06 && sizeValue <= 1.14) {
        setImgScaleVal(1.075);
      } else if (sizeValue >= 1.15 && sizeValue <= 1.24) {
        setImgScaleVal(1.15);
      } else if (sizeValue >= 1.25 && sizeValue <= 1.34) {
        setImgScaleVal(1.225);
      } else if (sizeValue >= 1.35) {
        setImgScaleVal(1.25);
      }
    } else if (response.data?.getReport?.payload?.shape === "Emerald") {
      if (sizeValue <= 1.14) {
        setImgScaleVal(0.775);
      } else if (sizeValue >= 1.15 && sizeValue <= 1.24) {
        setImgScaleVal(0.85);
      } else if (sizeValue >= 1.25 && sizeValue <= 1.34) {
        setImgScaleVal(0.925);
      } else if (sizeValue >= 1.35 && sizeValue <= 1.44) {
        setImgScaleVal(1);
      } else if (sizeValue >= 1.45) {
        setImgScaleVal(1.075);
      }
    }
      setTimeout(() => {
        setViewOnce(true);
      }, 1000);
      setLoader(false);
      const { getReport } = response?.data;
      setReportData(getReport);
      const { postId } = getReport || {};
      return {
        postId: postId,
      };
    } catch (error) {
      console.log("error", error);
    }
  };

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const formattedDate = `${date.getDate()}.${date.getMonth() + 1}.${
      date.getFullYear() % 100
    }`;
    return formattedDate;
  };
  console.log("reportData", reportData);

  return (
    <>
      <Helmet>
        <title>FCRF - True Face-Up</title>

        <meta property="og:title" content="True Face-Up" />
        <meta property="og:description" content="" />

        {/* <meta property="og:image" content={`${clientPath}/images/diamond-${metaImgData.color}-${metaImgData.shape}.svg`} /> */}
      </Helmet>
      {loader && <Loader type={"progress"} />}
      <div className="share-face-up-container">
        <div className="share-face-up-header">
          <img src={`${clientPath}/icons/FCRF_logo.svg`} alt="shape-1" />
        </div>
        <div className={`share-face-up-content-wrapper ${reportData?.payload?.giaNumber && "share-face-up-content-wrapper-gia"}`}>
          <div className="share-face-up-content-header">
            <p>Face Up ID#: {reportData?.postId?.replace(/#/g, "")}</p>
            {reportData?.payload?.giaNumber && (
              <span> GIA no. {reportData?.payload?.giaNumber}</span>
            )}
          </div>
          {reportData?.payload?.giaNumber ? (
            <>
              <div className={`true-face-up-result ${reportData?.payload?.giaNumber && "true-face-up-result-gia"}`}>
                <div className={`true-face-up-result-left ${ reportData?.payload?.giaNumber && "true-face-up-result-left-gia"}`}>
                  <div className="true-face-up-result-left-text">
                    {-2 <= reportData?.report?.D29 &&
                    reportData?.report?.D29 <= 0 ? (
                      <p>
                        The face up of this{" "}
                        <span>{reportData?.payload?.carat} </span> carat
                        translates to the visual appearance of a 
                        <span> {reportData?.report?.C37}</span>
                      </p>
                    ) : (
                      <>
                        <p>
                          The face up of this{" "}
                          <span>{reportData?.payload?.carat} </span> carat
                          translates to the visual appearance of a 
                          <span> {reportData?.report?.C37}</span>
                        </p>
                        <p>
                          which is{" "}
                          <span>{reportData?.report?.face_up_text} </span> than
                          the market standard
                        </p>
                      </>
                    )}
                  </div>

                
                </div>

                <div className="true-face-up-result-right">
                  <div className={`faceup-frame-content true-faceup-frame-content`}>
                    <div
                      className="faceup-frame-wrapper true-faceup-frame-wrapper share-faceup-frame-wrapper"
                      // ref={faceUpBoxAnimate}
                    >
                      <p className="">
                        result:{" "}
                        <span>
                          Looks like{" "}
                          {(
                            (parseFloat(
                              reportData?.report?.C37?.split("-")[0]
                            ) +
                              parseFloat(
                                reportData?.report?.C37?.split("-")[1]
                              )) /
                            2
                          )?.toFixed(2)}{" "}
                          ct - {reportData?.report?.D29?.toFixed(2)}% larger
                        </span>{" "}
                      </p>
                      <span className="diamondTxt1">
                        {reportData?.payload?.shape !== "Round"
                          ? `Your diamond ${reportData?.payload?.length}mm
x ${reportData?.payload?.width}mm`
                          : `Your average diameter is ${(
                              (reportData?.payload?.min_diam +
                                reportData?.payload?.max_diam) /
                              2
                            ).toFixed(2)}mm
  `}
                      </span>
                      <span className="diamondTxt2">
                        Average face-up for this weight
                      </span>
                      <div className={"faceup-frame"}>
                        <div
                          className="faceup-frame-images"
                          style={{
                            transform: `translateY(${
                              imgScaleVal > 1.24 ? "0" : "0"
                            }) scaleY(${imgScaleVal})`,
                          }}
                        >
                          <img
                            className={`face-card-image faceup-image-first ${
                              viewOnce ? "animate" : ""
                            }`}
                            src={`/images/diamond-${
                              DiaryDiamondColors.find(
                                (elem) =>
                                  elem?.value === reportData?.payload?.color
                              )?.img
                            }-${
                              DiaryDiamondShapes.find(
                                (item) =>
                                  item.value === reportData?.payload?.shape
                              )?.img
                            }.svg`}
                            alt={`new`}
                            style={{
                              transform: `translate(${
                                viewOnce ? "0%" : "98%"
                              }, ${
                                reportData?.payload?.shape === "Pear"
                                  ? "6%"
                                  : "0%"
                              })`,
                              width: `${
                                reportData?.report?.profiler_value === "1" ||
                                reportData?.report?.profiler_value === "2" ||
                                reportData?.report?.profiler_value === "3" ||
                                reportData?.report?.profiler_value === "0"
                                  ? "200px"
                                  : reportData?.report?.profiler_value === "-1"
                                  ? "190px"
                                  : reportData?.report?.profiler_value === "-2"
                                  ? "180px"
                                  : reportData?.report?.profiler_value === "-3"
                                  ? "170px"
                                  : ""
                              }`,
                            }}
                          />

                          <img
                            className={`face-card-image ${
                              viewOnce ? "animate" : ""
                            }`}
                            src={`/images/diamond-${
                              DiaryDiamondColors.find(
                                (elem) =>
                                  elem?.value === reportData?.payload?.color
                              )?.img
                            }-${
                              DiaryDiamondShapes.find(
                                (item) =>
                                  item.value === reportData?.payload?.shape
                              )?.img
                            }.svg`}
                            alt={`new`}
                            style={{
                              transform: `translateY(${
                                reportData?.payload?.shape === "Pear"
                                  ? "6%"
                                  : "0"
                              })`,
                              width: `${
                                reportData?.report?.profiler_value === "0" ||
                                reportData?.report?.profiler_value === "-1" ||
                                reportData?.report?.profiler_value === "-2" ||
                                reportData?.report?.profiler_value === "-3"
                                  ? "200px"
                                  : reportData?.report?.profiler_value === "1"
                                  ? "190px"
                                  : reportData?.report?.profiler_value === "2"
                                  ? "180px"
                                  : reportData?.report?.profiler_value === "3"
                                  ? "170px"
                                  : ""
                              }`,
                              // opacity: `${viewOnce ? "1" : "0"},`,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="share-face-up-content">
                <div className="share-face-up-content-image">
                  <img
                    src={`${clientPath}/images/diamond-${
                      reportData?.type === "discoverWeight"
                        ? "light-grey"
                        : DiaryDiamondColors.find(
                            (elem) => elem?.value === reportData?.payload?.color
                          )?.img
                    }-${
                      DiaryDiamondShapes.find(
                        (item) => item.value === reportData?.payload?.shape
                      )?.img
                    }.svg`}
                    alt="img"
                  />
                </div>
                <p className="share-face-up-content-report-text">
                  Your carat range for a{" "}
                  <span>
                    {reportData?.payload?.shape} Shape,{" "}
                    {reportData?.payload?.length} x {reportData?.payload?.width}
                  </span>{" "}
                  is:
                </p>
                <h6>
                  {reportData?.report?.C37?.split("-")[0]} to{" "}
                  {reportData?.report?.C37?.split("-")[1]} carat{" "}
                  <sup className="discover-weight-trueFace share-weight-trueFace">
                  
                    <InfoIcon className="info-icon" />
                    <div className="discover-weight-info">
                      <p>
                        These measurements reflect the weight of an average{" "}
                        {reportData?.report?.C37?.replace(/-/, "to")} carat,
                        fancy color pear shape diamond.
                      </p>
                    </div>
                  </sup>
                </h6>
              </div>
            </>
          )}

          <div className="share-face-up-footer">
            <p>
              Report generated on {formatDate(reportData?.timestamp)} | Last
              data update on 04.09.24
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShareTrueFacePage;

const InfoIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="7"
        cy="7"
        r="6.33333"
        stroke="#BF8F02"
        stroke-width="1.33333"
      />
      <line
        x1="6.88932"
        y1="6.22266"
        x2="6.88932"
        y2="10.8893"
        stroke="#BF8F02"
        stroke-width="1.33333"
      />
      <circle cx="7.00043" cy="3.88911" r="0.777778" fill="#BF8F02" />
    </svg>
  );
};
