import React, { useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { withAuth } from "../../config/axios";
import {
  DiaryDiamondColors,
  DiaryDiamondShapes,
} from "../../utils/dairyDiamondsDetail";
import TrueFaceupModel from "../Models/trueFaceupModel";
import { serverPath } from "../../config/key";
import { UAParser } from "ua-parser-js";

const DiscoverTrueFaceUp = ({
  trueFaceUpResult,
  setTrueFaceUpResult,
  setUrlValue,
}) => {
  const [viewOnce, setViewOnce] = useState(false);
  const [showError, setShowError] = useState(false);
  const [type, setType] = useState("");

  const calculateTrueFaceUp = () => {
    setTrueFaceUpResult({});
    setGiaNumber("");
  };

  const [, setGIATextError] = useState(false);
  const [giaNumber, setGiaNumber] = useState("");
  const [giaLoading, setGiaLoading] = useState(false);
  const [imgScaleVal, setImgScaleVal] = useState(1);

  const handleGIAInput = (e) => {
    const { value } = e.target || {};
    setGiaNumber(value);
    setGIATextError(false);
  };
  const handleGIASubmit = async () => {
    // console.log("giaNumber", giaNumber);
    if (!giaNumber || giaNumber.length < 7 || giaNumber.length > 10) {
      setGIATextError(true);
      return;
    }
    setGIATextError(false);
    setGiaLoading(true);
    try {
      const ua = navigator.userAgent;
      const [ipResponse, uaResult] = await Promise.all([
        fetch("https://api.ipify.org?format=json"),
        new Promise((resolve) => resolve(new UAParser(ua).getResult())),
      ]);

      const { ip } = await ipResponse.json(); 
      const response = await withAuth().post("truefaceup/discover-truefaceup", {
        giaNumber,
        services: {
          IP: ip,
          "Operating System": uaResult.os.name,
          Browser: uaResult.browser.name,
          Device: uaResult.device.model || "Unknown",
        }
      });
      if (response.data.status === 200) {
        setUrlValue(
          `${serverPath}/share-faceup-report/${response.data?.data?.Id}`
        );
        setTrueFaceUpResult(response.data?.data);
        const sizeValueInit =
          response.data?.data?.length / response.data?.data?.width || 0;

        let sizeValue =
          sizeValueInit.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] || 0;
        if (
          response.data?.data?.shape === "Cushion" ||
          response.data?.data?.shape === "Radiant"
        ) {
          if (sizeValue <= 1.05) {
            setImgScaleVal(1);
          } else if (sizeValue >= 1.06 && sizeValue <= 1.14) {
            setImgScaleVal(1.075);
          } else if (sizeValue >= 1.15 && sizeValue <= 1.24) {
            setImgScaleVal(1.15);
          } else if (sizeValue >= 1.25 && sizeValue <= 1.34) {
            setImgScaleVal(1.225);
          } else if (sizeValue >= 1.35) {
            setImgScaleVal(1.25);
          }
        } else if (response.data?.data?.shape === "Emerald") {
          if (sizeValue <= 1.14) {
            setImgScaleVal(0.775);
          } else if (sizeValue >= 1.15 && sizeValue <= 1.24) {
            setImgScaleVal(0.85);
          } else if (sizeValue >= 1.25 && sizeValue <= 1.34) {
            setImgScaleVal(0.925);
          } else if (sizeValue >= 1.35 && sizeValue <= 1.44) {
            setImgScaleVal(1);
          } else if (sizeValue >= 1.45) {
            setImgScaleVal(1.075);
          }
        }
        setGiaNumber("");
        setGiaLoading(false);
        setViewOnce(false);
        setImgScaleVal(1);
      } else if (response.data.status === 403) {
        setShowError(true);
        setType("truefaceup-member");
        setGiaLoading(false);
      } else if (response.data.status === 401) {
        setShowError(true);
        setType("truefaceup-unauthorized");
        setGiaLoading(false);
      } else {
        setShowError(true);
        setType(response.data?.type || response.data?.msg);
        setGiaLoading(false);
      }
    } catch (error) {
      setGiaLoading(false);
      console.log("error", error);
      console.log("error in discover api", error);
    }
  };
  setTimeout(() => {
    setViewOnce(true);
  }, 500);

  return Object.keys(trueFaceUpResult).length ? (
    <div className="true-face-up-result-main">
      {/* <div className="true-face-up-result-heading">
        <h4 className="rarity-top-heading">true face-up result</h4>
      </div> */}

      <div className="true-face-up-result">
        <div className="true-face-up-result-left">
          <div>
          { trueFaceUpResult?.D29 >= 2 ? 
            <div>
              The face up of this <span>{trueFaceUpResult?.carat} </span> carat
              translates to the visual appearance of a <span> {trueFaceUpResult?.C37}</span> <strong>
             
                { `( ${Math.round(trueFaceUpResult?.D29)?.toFixed(2)} % Larger)`}
                </strong>
            </div>
           : ( -2 <= trueFaceUpResult?.D29 && trueFaceUpResult?.D29 <= 2)? <>
           <div>
           The face up of this {trueFaceUpResult?.carat} carat <span>is true to its weight</span>
            </div>
          
          </>:<>
          <div>
              The face up of this <span>{trueFaceUpResult?.carat} </span> carat
              translates to the visual appearance of a
              <span> {trueFaceUpResult?.C37}</span> <strong>{`(${Math.round(trueFaceUpResult?.D29)}% Smaller)`} </strong>
            </div></> }

          </div>
         
          <div className="true-face-up-btn">
            <button
              type="button"
              className="btn btn-outline-warning"
              onClick={calculateTrueFaceUp}
            >
              <AddIcon /> Discover Another
            </button>
          </div>
        </div>

        <div className="true-face-up-result-right">
          <div className="faceup-frame-content true-faceup-frame-content">
            <div
              className="faceup-frame-wrapper true-faceup-frame-wrapper"
              // ref={faceUpBoxAnimate}
            >
              <p className="">
                result:{" "}
                <span>
                  Looks like{" "}
                  {(
                    (parseFloat(trueFaceUpResult?.C37?.split("-")[0]) +
                      parseFloat(trueFaceUpResult?.C37?.split("-")[1])) /
                    2
                  )?.toFixed(2)}{" "}
                  ct - {trueFaceUpResult?.D29?.toFixed(2)}% larger
                </span>{" "}
              </p>
              <span className="diamondTxt1">
                {trueFaceUpResult?.shape !== "Round"
                  ? `Your diamond ${trueFaceUpResult?.length}mm
x ${trueFaceUpResult?.width}mm`
                  : `Your average diameter is ${(
                      (trueFaceUpResult?.min_diam +
                        trueFaceUpResult?.max_diam) /
                      2
                    ).toFixed(2)}mm
  `}
              </span>
              <span className="diamondTxt2">
                Average face-up for this weight
              </span>
              <div className={"faceup-frame"}>
                <div
                  className="faceup-frame-images"
                  style={{
                    transform: `translateY(${
                      imgScaleVal > 1.24 ? "0" : "0"
                    }) scaleY(${imgScaleVal})`,
                  }}
                >
                  <img
                    className={`face-card-image faceup-image-first ${
                      viewOnce ? "animate" : ""
                    }`}
                    src={`/images/diamond-${
                      DiaryDiamondColors.find(
                        (elem) => elem?.value === trueFaceUpResult?.color
                      )?.img
                    }-${
                      DiaryDiamondShapes.find(
                        (item) => item.value === trueFaceUpResult?.shape
                      )?.img
                    }.svg`}
                    alt={`new`}
                    style={{
                      transform: `translate(${viewOnce ? "0%" : "98%"}, ${
                        trueFaceUpResult?.shape === "Pear" ? "6%" : "0%"
                      })`,
                      width: `${
                        trueFaceUpResult?.profiler_value === "1" ||
                        trueFaceUpResult?.profiler_value === "2" ||
                        trueFaceUpResult?.profiler_value === "3" ||
                        trueFaceUpResult?.profiler_value === "0"
                          ? "200px"
                          : trueFaceUpResult?.profiler_value === "-1"
                          ? "190px"
                          : trueFaceUpResult?.profiler_value === "-2"
                          ? "180px"
                          : trueFaceUpResult?.profiler_value === "-3"
                          ? "170px"
                          : ""
                      }`,
                    }}
                  />

                  <img
                    className={`face-card-image ${viewOnce ? "animate" : ""}`}
                    src={`/images/diamond-${
                      DiaryDiamondColors.find(
                        (elem) => elem?.value === trueFaceUpResult?.color
                      )?.img
                    }-${
                      DiaryDiamondShapes.find(
                        (item) => item.value === trueFaceUpResult?.shape
                      )?.img
                    }.svg`}
                    alt={`new`}
                    style={{
                      transform: `translateY(${
                        trueFaceUpResult?.shape === "Pear" ? "6%" : "0"
                      })`,
                      width: `${
                        trueFaceUpResult?.profiler_value === "0" ||
                        trueFaceUpResult?.profiler_value === "-1" ||
                        trueFaceUpResult?.profiler_value === "-2" ||
                        trueFaceUpResult?.profiler_value === "-3"
                          ? "200px"
                          : trueFaceUpResult?.profiler_value === "1"
                          ? "190px"
                          : trueFaceUpResult?.profiler_value === "2"
                          ? "180px"
                          : trueFaceUpResult?.profiler_value === "3"
                          ? "170px"
                          : ""
                      }`,
                      opacity: `${viewOnce ? "1" : "0"},`,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="diamond-weight-container" id="diamondWeight">
      <Form
        // onSubmit={handleGIASubmit}
        className="center-gia-input "
      >
        <div class="rarity-tab-beta true-face-up-tab1">
          <h6>TYPE GIA #</h6>
        </div>
        <Form.Group className={`diamond-weight true-face-up-gia-input`}>
          <Form.Control
            className="true-faceup-gia-input"
            type="number"
            placeholder="GIA Report Number"
            pattern="/^\d{7,10}$/"
            name="number"
            onChange={(e) => handleGIAInput(e)}
            value={giaNumber}
            //ref={inputRef}
            onWheel={(e) => e.target.blur()}
            autoFocus={true}
          />
          {/* <span className="gia-below-text">GIA #</span>
          {giainputError && (
            <span className="error-text">
              Please check the number you entered
            </span>
          )} */}
        </Form.Group>
        <div className="rarity-process-content rarity-gia-btn ">
          <Button
            className="processBtn newProcessBtn"
            disabled={
              giaNumber?.length < 7 || giaNumber?.length > 10 ? true : false
            }
            onClick={() => handleGIASubmit()}
            // type="submit"
          >
            {giaLoading ? <Spinner animation="border" /> : "Calculate"}
          </Button>
        </div>
      </Form>
      <TrueFaceupModel show={showError} setShow={setShowError} type={type} />
    </div>
  );
};

export default DiscoverTrueFaceUp;

const AddIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 9.14286H9.14286V16H6.85714V9.14286H0V6.85714H6.85714V0H9.14286V6.85714H16V9.14286Z"
        fill="#BF8F02"
      />
    </svg>
  );
};
