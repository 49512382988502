import React, { useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { withAuth } from "../../config/axios";
import { clientPath, serverPath } from "../../config/key";
import { UAParser } from "ua-parser-js";

const DiscoverWeight = ({ discoverShape, weightResult, setWeightResult , setUrlValue , weightInput , setWeightInput }) => {
 
  const [weightInputError, setWeightInputError] = useState({
    shape: "",
    length: "",
    weight: "",
  });
  const [weightLoading, setWeightLoading] = useState(false);
  const [, setShowError] = useState(false);
  const [, setType] = useState("");

  const calculateWeight = async () => {
    setWeightInput({ shape: "", length: "", weight: "" });
    setWeightInputError({
      ...weightInputError,
      shape: "",
      length: "",
      weight: "",
    });
    setWeightResult("");
  };

  const handleWeightChange = (e, el) => {
    const { value, name } = e.target || {};
    if (name === "shape") {
      setWeightInput({
        ...weightInput,
        shape: el.shape,
      });
    } else {
      setWeightInput({
        ...weightInput,
        [name]: value,
      });
    }
    setWeightInputError({
      ...weightInputError,
      shape: "",
      length: "",
      weight: "",
    });
    // console.log("weightInput", weightInput);
  };
  const handleWeightSubmit = async (e) => {
    e.preventDefault();
    if (!weightInput.shape) {
      setWeightInputError({ ...weightInputError, shape: "shape is required" });
    }

    if (!weightInput.length) {
      setWeightInputError({
        ...weightInputError,
        length: "length is required",
      });
    }

    if (!weightInput.weight) {
      setWeightInputError({
        ...weightInputError,
        weight: "weight is required",
      });
    }

    setWeightLoading(true);
    try {
      const ua = navigator.userAgent;
      const [ipResponse, uaResult] = await Promise.all([
        fetch("https://api.ipify.org?format=json"),
        new Promise((resolve) => resolve(new UAParser(ua).getResult())),
      ]);
      const { ip } = await ipResponse.json();      
      const response = await withAuth().post("truefaceup/discover-weight", {
        ...weightInput,
        width: weightInput?.weight,
        carat: "1",
        services:{
          IP: ip,
          "Operating System": uaResult.os.name,
          Browser: uaResult.browser.name,
          Device: uaResult.device.model || "Unknown",
        }
      });
      // console.log("response", response);
      if (response.data.status === 200) {
        setWeightLoading(false);
        setWeightResult(response.data?.data);
        setUrlValue(`${serverPath}/share-faceup-report/${response.data?.data?.Id}`);
      } else if (response.data.status === 403) {
        setShowError(true);
        setType("truefaceup-member");
        setWeightLoading(false);
      } else if (response.data.status === 401) {
        setShowError(true);
        setType("truefaceup-unauthorized");
        setWeightLoading(false);
      } else {
        setShowError(true);
        setType(response.data?.type || response.data?.msg);
        setWeightLoading(false);
      }
    } catch (err) {
      setWeightLoading(false);
      // setShowError(true);
      // setType(err?.response?.data?.type || err?.response?.data?.msg || "Internal server error");
      console.log("err", err);
    }
  };

  return (
    <>
      <div >
        {!Object.keys(weightResult).length ? (
          <div className="discover-weight-container">
            <div className="discover-weight-top">
              <h5>
                Predetermine the weight of color diamonds using the jewelry
                design
              </h5>
            </div>
            <div className="discover-weight-content">
              <p>select shape</p>
              <div className="diamond-shape-grid discover-weight-grid">
                {discoverShape?.map((el, i) => (
                  <Form.Group
                    className="diamond-btn-wrapper discover-weight-wrapper"
                    key={i}
                  >
                    <input
                      type="radio"
                      id={`diamond${el.label}`}
                      name="shape"
                      onChange={(e) => handleWeightChange(e, el)}
                      checked={weightInput?.shape === el.shape}
                    />
                    <div
                      className={`diamond-color-box ${
                        el.shape === "Pear" || el.shape === "Marquise"
                          ? "discover-weight-color-box-extra"
                          : "discover-weight-color-box"
                      }`}
                    >
                      <img
                        className={`diamond-saturation ${el}`}
                        src={`images/diamond-light-grey-${el.shape?.toLowerCase()}.svg`}
                        alt={el}
                      />
                      <span>{el.label}</span>
                    </div>
                  </Form.Group>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="discover-weight-input-container">
            <div className={"faceup-frame discover-weight-frame"}>
              <div className={`discover-weight-faceup-image`}>
                <img
                  src={`${clientPath}/images/diamond-light-grey-${weightInput?.shape?.toLowerCase()}.svg`}
                  alt="diamond-white-oval"
                />
              </div>
            </div>
            <div className="discover-weight-result">
                <p>Your range:</p>
                <h6>
                  {weightResult?.C37?.replace("-", "to")} carat{" "}
                
                </h6>
                <sup className="discover-weight-trueFace" >
                    <InfoIcon className="info-icon" />
                    <div className="discover-weight-info">
                      <p>
                        These measurements reflect the weight of an average{" "}
                        {weightResult?.C37?.replace("-", "to")} carat, fancy color
                        pear shape diamond.
                      </p>
                    </div>
                  </sup>
              </div>
          </div>
        )}
        <div className="discover-weight-select-size">
          {!Object.keys(weightResult).length && (
            <p>select size</p>
          )}
          
          <div className="diamond-weight-container discover-weight-container">
            <Form
              // onSubmit={calculateWeight}
              className="center-gia-input  discover-weight-box"
            >
              <Form.Group
                className={`diamond-weight true-face-up-gia-input discover-weight-input faceup-length-input`}
              >
                <Form.Control
                  className=""
                  type="number"
                  placeholder="Length"
                  // pattern="/^\d{7,10}$/"
                  disabled={Object.keys(weightResult)?.length}
                  name="length"
                  onChange={(e) => handleWeightChange(e)}
                  //ref={inputRef}
                  onWheel={(e) => e.target.blur()}
                  autoFocus={true}
                  value={weightInput.length}
                />
                {!weightInput.length && (
                  <div className="length-icon">
                  <LengthIcon />
                </div>
                  
                )}
                
                <span className="gia-below-text">Length (mm)</span>
                {!!weightInputError.length && (
                  <span className="error-text">
                    Please check the number you entered
                  </span>
                )}
              </Form.Group>
              <Form.Group className={`diamond-weight true-face-up-gia-input faceup-width-input`}>
                <Form.Control
                  className=""
                  type="number"
                  placeholder="Width "
                  // pattern="/^\d{7,10}$/"
                  name="weight"
                  onChange={(e) => handleWeightChange(e)}
                  disabled={Object.keys(weightResult)?.length}
                  //ref={inputRef}
                  onWheel={(e) => e.target.blur()}
                  value={weightInput.weight}
                  autoFocus={true}
                />
                {!weightInput.weight && (
                   <div className="width-icon">
                   <WidthIcon />
                 </div>
                )}
               
                <span className="gia-below-text">Width (mm)</span>
                {!!weightInputError.weight && (
                  <span className="error-text">
                    Please check the number you entered
                  </span>
                )}
              </Form.Group>
            </Form>
          </div>
        </div>
        {Object.keys(weightResult).length >0 ? (
          <div className="true-face-up-btn weight-discover-btn">
            <button
              type="button"
              className="btn btn-outline-warning"
              onClick={calculateWeight}
            >
              <AddIcon /> Discover Another
            </button>
          </div>
        ) : (
          <div className="discover-weight-button">
            <Button
              className="processBtn"
              disabled={
                weightLoading ||
                !weightInput.shape ||
                !weightInput.length ||
                !weightInput.weight
              }
              onClick={handleWeightSubmit}
              type="submit"
            >
              {weightLoading ? <Spinner animation="border" /> : "Calculate"}
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default DiscoverWeight;

const AddIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 9.14286H9.14286V16H6.85714V9.14286H0V6.85714H6.85714V0H9.14286V6.85714H16V9.14286Z"
        fill="#BF8F02"
      />
    </svg>
  );
};

const InfoIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="7"
        cy="7"
        r="6.33333"
        stroke="#BF8F02"
        stroke-width="1.33333"
      />
      <line
        x1="6.88932"
        y1="6.22266"
        x2="6.88932"
        y2="10.8893"
        stroke="#BF8F02"
        stroke-width="1.33333"
      />
      <circle cx="7.00043" cy="3.88911" r="0.777778" fill="#BF8F02" />
    </svg>
  );
};


const LengthIcon = () => {
  return (
<svg width="10" height="18" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.5" d="M5.69477 21.5303C5.98766 21.8232 6.46253 21.8232 6.75543 21.5303L11.5284 16.7574C11.8213 16.4645 11.8213 15.9896 11.5284 15.6967C11.2355 15.4038 10.7606 15.4038 10.4677 15.6967L6.2251 19.9393L1.98246 15.6967C1.68956 15.4038 1.21469 15.4038 0.921797 15.6967C0.628903 15.9896 0.628903 16.4645 0.921797 16.7574L5.69477 21.5303ZM6.75543 0.772388C6.46253 0.479496 5.98766 0.479496 5.69477 0.772388L0.921797 5.54536C0.628903 5.83825 0.628903 6.31313 0.921797 6.60602C1.21469 6.89891 1.68956 6.89891 1.98246 6.60602L6.2251 2.36338L10.4677 6.60602C10.7606 6.89891 11.2355 6.89891 11.5284 6.60602C11.8213 6.31313 11.8213 5.83825 11.5284 5.54536L6.75543 0.772388ZM6.9751 21L6.9751 1.30272H5.4751L5.4751 21H6.9751Z" fill="#333333"/>
</svg>

  );
};

const WidthIcon = () => {
  return (
    <svg width="18" height="10" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.5" d="M0.5434 5.46967C0.250507 5.76256 0.250507 6.23744 0.5434 6.53033L5.31637 11.3033C5.60926 11.5962 6.08414 11.5962 6.37703 11.3033C6.66992 11.0104 6.66992 10.5355 6.37703 10.2426L2.13439 6L6.37703 1.75736C6.66992 1.46447 6.66992 0.989593 6.37703 0.696699C6.08414 0.403806 5.60926 0.403806 5.31637 0.696699L0.5434 5.46967ZM21.3013 6.53033C21.5942 6.23744 21.5942 5.76256 21.3013 5.46967L16.5284 0.696699C16.2355 0.403806 15.7606 0.403806 15.4677 0.696699C15.1748 0.989593 15.1748 1.46447 15.4677 1.75736L19.7104 6L15.4677 10.2426C15.1748 10.5355 15.1748 11.0104 15.4677 11.3033C15.7606 11.5962 16.2355 11.5962 16.5284 11.3033L21.3013 6.53033ZM1.07373 6.75H20.771V5.25H1.07373V6.75Z" fill="#333333"/>
</svg>

  );  
};