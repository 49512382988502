/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect,  } from "react";
import Loader from "../loader/loader";
import RarityToolCard from "./rarityToolCard";
import GemologicalCharacteristics from "./gemologicalCharacteristics";
import { withoutAuth } from "../../config/axios";
import { resultFormating } from "../../utils/resultFormating";
import { queryString } from "../../utils/queryString";
import { Helmet } from "react-helmet";
import { getFormattedDate } from "../../utils/dateUtils";
import { clientPath } from "../../config/key";
import { DiaryDiamondColors, DiaryDiamondShapes } from "../../utils/dairyDiamondsDetail";
import { useLocation, useParams } from "react-router-dom";
import { decryptString, extractShareIdByKey } from "../../utils/decryptText";
import RarityHighlightedBox from "./rarityHighlightedBox";
import RarityInfo from "./rarityInfo";
import "./rarityReport.css";

import ScrollToTop from "../common/scrolltotop";
import MarketDistribution from "./marketDistribution";
const ShareRarityReport = () => {
  const { id } = queryString();
  const [loading, setLoading] = useState(true);
  const [characteristics, setCharacteristics] = useState({});
  const [bubble_texts, setBubbleText] = useState({});
  const [range_slider, setRangeSlider] = useState({});
  const [labelName, setLabelName] = useState([]);
  const [tooltip_text, setTooltipText] = useState([]);
  const [labelValue, setLabelValue] = useState([]);
  const[animationText,setAnimationText]=useState(null)
  const [reportData, setReportData] = useState({});
  const [report_number, setReportNumber] = useState(null);
  const [, setTime] = useState(null);
  const [, setImgScaleVal] = useState(1);
  const [metaImgData, setMetaImgData] = useState({
    color:"",
    shape:""
  })
  const sharerarityIds = useParams();
  const [companyLogo, setCompanyLogo] = useState("");
  const [custom_branding, setCustomBranding] = useState("0");
  const [showContent, setShowContent] = useState(false);
  // const [showGemological, setShowGemological] = useState(false);
  const location = useLocation();


  useEffect(() => {
    async function fetchData(_id) {
      const {postId,createdBy} = await getReportById(_id);
      await sharePageLoaded(postId,createdBy);
    }
    const params = location.search;
    // Check for the legacy link
    if (params?.includes("?id")) {
      const decData = decryptString(id);
      const shareIdvalue = extractShareIdByKey(decData, "Id");
      fetchData(shareIdvalue);
    } else {
      // Check for the updated link
      const rarityPayload = { id: sharerarityIds?.id };
      fetchData(rarityPayload.id);
    }
  }, [id]);



  const AnimateStringData = (inputString) => {
    let Text = inputString?.split("#") || [];
    const parts = Text[1]?.split("~");

    if (parts?.length === 2) {

      const secondString = parts[1].trim().replace(/,/g,"")
      console.log(secondString);
      setAnimationText(Number(secondString))
      return secondString;
    } else {
      setAnimationText(null)
      return null;
    }
  };
  const sharePageLoaded = async (postId,createdBy) => {
    try {
      await withoutAuth().post("report/share-link-open/Rarity", {
        reportId: postId,
        createdBy,
        shareUrl: window.location.href,
      });
    } catch (error) {
      console.error("error in open link===", error);
    }
  };

  const getReportById = async (id) => {
    try {
      const response = await withoutAuth().post("report/getreportbyId", {
        id,
      });
      const { getReport, companyInfo,createdBy} = response?.data;
      const reportData = getReport?.report || {};
      if (reportData) {
        const {  data, graph_texts, bubble_texts, range_slider } =
          reportData;
        const { labelname, graph_tooltip_text, labelvalue } = resultFormating(
          graph_texts,
          range_slider,
          data
        );
       
        const times = getFormattedDate(reportData.timestamp);
        setCompanyLogo(companyInfo?.companyLogo || "")
        setCustomBranding(companyInfo?.customBranding || "0")
        setReportData(reportData);
        setReportNumber(reportData?.data?.report_number);
        setLabelName(labelname);
        setLabelValue(labelvalue);
        setTooltipText(graph_tooltip_text);
        setBubbleText(bubble_texts);
        AnimateStringData(bubble_texts?.D18)
        setRangeSlider(range_slider);
        setCharacteristics(data);
        setTime(times);

        const Imgshape= DiaryDiamondShapes.filter((elem) => getReport?.report?.data?.shape === elem.value)
        const imgColorShape = DiaryDiamondColors.filter((elem) => getReport?.report.data?.color === elem.value);
        setMetaImgData({color:imgColorShape[0]?.img , shape: Imgshape[0]?.img})

      }
      setLoading(false);
      return ({postId:reportData?.postId,createdBy});
    } catch (error) {
      console.log("error", error);
    }
  };

 
  const sizeValueInit =
    reportData?.gia_report?.results?.data?.measurements?.length /
      reportData?.gia_report?.results?.data?.measurements?.width || 0;

  const sizeValue =
    Number(sizeValueInit.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]) || 0;

  console.log("sizeValueInit>>>", sizeValueInit);
  console.log("sizeValue>>>", sizeValue);

  useEffect(() => {
    if (
      reportData?.data?.shapeCategory === "Cushion" ||
      reportData?.data?.shapeCategory === "Radiant"
    ) {
      if (sizeValue <= 1.05) {
        setImgScaleVal(1);
      } else if (sizeValue >= 1.06 && sizeValue <= 1.14) {
        setImgScaleVal(1.075);
      } else if (sizeValue >= 1.15 && sizeValue <= 1.24) {
        setImgScaleVal(1.15);
      } else if (sizeValue >= 1.25 && sizeValue <= 1.34) {
        setImgScaleVal(1.225);
      } else if (sizeValue >= 1.35) {
        setImgScaleVal(1.3);
      }
    } else if (reportData?.data?.shapeCategory === "Emerald") {
      if (sizeValue <= 1.14) {
        setImgScaleVal(0.775);
      } else if (sizeValue >= 1.15 && sizeValue <= 1.24) {
        setImgScaleVal(0.85);
      } else if (sizeValue >= 1.25 && sizeValue <= 1.34) {
        setImgScaleVal(0.925);
      } else if (sizeValue >= 1.35 && sizeValue <= 1.44) {
        setImgScaleVal(1);
      } else if (sizeValue >= 1.45) {
        setImgScaleVal(1.075);
      }
    }
  }, [sizeValue]);

  const formatDateReport = (input) => {
    const dateObject = new Date(input);
    if (isNaN(dateObject.getTime())) {
      return 'Invalid Date';
    }
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1;
    const year = dateObject.getFullYear();
    const lastTwoDigitsOfYear=year % 100;
    const formattedDate = `${day}.${month}.${lastTwoDigitsOfYear}`;
    return formattedDate;
  };
  const formatDate = (inputDate) => {
    const dateComponents = inputDate?.match(/(\w+) (\d+)(?:st|nd|rd|th), (\d+)/);
    if (!dateComponents) {
      return 'Invalid Date';
    }
    const months = {
      January: 0,
      February: 1,
      March: 2,
      April: 3,
      May: 4,
      June: 5,
      July: 6,
      August: 7,
      September: 8,
      October: 9,
      November: 10,
      December: 11,
    };
    const month = months[dateComponents[1]];
    const day = dateComponents[2];
    const year = dateComponents[3];
    const date = new Date(year, month, day);
    const lastTwoDigitsOfYear=year % 100;
    const formattedDate = `${date.getDate()}.${date.getMonth() + 1}.${lastTwoDigitsOfYear}`;
    return formattedDate;
  };


  const formatDateWebReport = (input) => {
    const dateObject = new Date(input);
    if (isNaN(dateObject.getTime())) {
      return 'Invalid Date';
    }
    const day = dateObject.getDate();
    const month = dateObject.getMonth()+1 ;
    let monthName;

    switch (month) {
      case 1: monthName = "January"; break;
      case 2: monthName = "February"; break;
      case 3: monthName = "March"; break;
      case 4: monthName = "April"; break;
      case 5: monthName = "May"; break;
      case 6: monthName = "June"; break;
      case 7: monthName = "July"; break;
      case 8: monthName = "August"; break;
      case 9: monthName = "September"; break;
      case 10: monthName = "October"; break;
      case 11: monthName = "November"; break;
      case 12: monthName = "December"; break;
      default: monthName = "Invalid month";
  }
    const year = dateObject.getFullYear();
    // const lastTwoDigitsOfYear=year % 100;
    const formattedDate = `${monthName} ${day}, ${year}`;
    return formattedDate;
  };
  const highlightText =  reportData?.bubble_texts?.D18?.split("#")[1]?.split(" ")?.at(-1) || '';
    // const highlightYear =  reportData?.bubble_texts?.H18?.split("#")[1]
  return (
    <>
      <Helmet>
        <title>FCRF - Rarity Report</title>

        <meta property="og:title" content="Rarity Report"/>
        <meta property="og:description" content="your Rarity Report"/>

        <meta property="og:image" content={`${clientPath}/images/diamond-${metaImgData.color}-${metaImgData.shape}.svg`} />
      </Helmet>
      {loading && <Loader type={"progress"} />}

      <div className="rarity-report-page shared-rarity-report-page">
        <div className="rarity-report-head"></div>
        <div className="rarity-report-content">
          <div className="container">
            <div className="rarity-tool">

            <div className="rarity-tool-grid updatedrarity-tool-grid ">
            <div className="updated_rarity_pdf">
                    <div className="rarity_report_top">
                      <div className="rarity_report_top_left">
                        <div className="rarity-fcrf-logo">
                          <img  src={`${ clientPath }/icons/FCRF_logo.svg`} alt="Logo" />
                        </div>
                        <div className="rarity_report_top_left_desc">
                         
                          <p className={`${(report_number ===""|| !report_number) ? "gemological-extra-line-for-manual" :"" }`}>
                            <div className="rarity-gemological-text">
                              Carat weight:
                            </div>
                            <div className={`${(report_number ===""|| !report_number) ? "dotted-line-for-manual" : "dotted-line" }`}></div>
                            <div className="rarity-gemological-text">{parseFloat(reportData?.data?.weight).toFixed(2)}</div>
                          </p>
                          <p className={`${(report_number ===""|| !report_number) ? "gemological-extra-line-for-manual" :"" }`}>
                            <div className="rarity-gemological-text">
                              Saturation:
                            </div>
                            <div className={`${(report_number ===""|| !report_number) ? "dotted-line-for-manual" : "dotted-line" }`}></div>
                            <div className="rarity-gemological-text">{reportData?.data?.saturation}</div>
                          </p>
                          <p className={`${(report_number ===""|| !report_number) ? "gemological-extra-line-for-manual" :"" }`}>
                            <div className="rarity-gemological-text">
                              Color:
                            </div>
                            <div className={`${(report_number ===""|| !report_number) ? "dotted-line-for-manual" : "dotted-line" }`}></div>
                            <div className="rarity-gemological-text">
                            {reportData?.data?.report_number === "" || !reportData?.data?.report_number ? reportData?.data?.color : reportData?.data?.colorName} {(reportData?.data?.color ==="Chameleon" && reportData?.data?.report_number) && "Chameleon"
                          }
                            </div>
                          </p>
                          <p className={`${(report_number ===""|| !report_number) ? "gemological-extra-line-for-manual" :"" }`}>
                            <div className="rarity-gemological-text">
                              Shape:
                            </div>
                            <div className={`${(report_number ===""|| !report_number) ? "dotted-line-for-manual" : "dotted-line" }`}></div>
                            <div className="rarity-gemological-text">
                            {reportData?.data?.report_number === "" || !reportData?.data?.report_number ? reportData?.data?.shape : reportData?.range_slider?.shapeName}
                            </div>
                          </p>
                          <p className={`${(report_number ===""|| !report_number) ? "gemological-extra-line-for-manual" :"" }`}>
                            <div className="rarity-gemological-text">
                              Clarity:
                            </div>
                            <div className={`${(report_number ===""|| !report_number) ? "dotted-line-for-manual" : "dotted-line" }`}></div>
                            <div className="rarity-gemological-text">{reportData?.data?.report_number === "" || !reportData?.data?.report_number ? reportData?.data?.clarity : reportData?.data?.giaclarity}</div>
                          </p>
                       {
                        !(reportData?.data?.report_number === "" || !reportData?.data?.report_number) ? (
                          <p className={`${(report_number ===""|| !report_number) ? "gemological-extra-line-for-manual" :"" }`} style={{
                            color: '#767676'
                          }}>
                            <div className="rarity-gemological-text">
                              GIA
                            </div>
                            <div className={`${(report_number ===""|| !report_number) ? "dotted-line-for-manual" : "dotted-line" }`} style={{borderBottom: '3px dotted #767676'}}></div>
                            <div className="rarity-gemological-text">
                            No. {reportData?.data?.report_number}
                            </div>
                          </p>
                        ) : null
                       }

{/* {(reportData?.data?.color ==="Chameleon" && reportData?.data?.report_number) &&  <div>
                            <span>*Chameleon</span>
                          </div> } */}

                        </div>
                      </div>
                      <div className="rarity_report_top_right">
                        <div className="diamond-desc-img">
                          <div className="rarity-right-title">
                            <h4>The Rarity Report</h4> 
                            <span>
                            <CIcon />
                            </span>
                           
                          </div>

                          <div className="rarity-right-img-data">
                            <p className="report-date m-0">
                              {formatDateWebReport(reportData?.timestamp)}
                            </p>
                            <p className="report-number m-0">
                              REPORT {reportData?.postId}
                            </p>
                          </div>

                          <div className="divider"></div>

                          

                          <div className={`rarity-note  ${!(report_number ===""|| !report_number) && "rarity-note-for-gia"}`}>
                            Rarity data refers to a natural color diamond with
                            the described gemological properties
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="diamond-grade ">
                      <div className="grade-section">
                        <p className="text-block">
                          <span className="main-text">rarity</span>
                          <span className="highlight-text">
                            ONE IN {highlightText}
                          </span>
                        </p>

                        <p className="text-block">
                          <span className="main-text">market entry</span>
                          <span className="highlight-text market-highlight">
                            {" "}
                            {reportData?.bubble_texts?.H18?.replaceAll(
                              "#",
                              ""
                            )}{" "}
                          </span>

                        </p>
                      </div>
                    </div>

                    <div className="graph_section_rarity">
                      <div className="graph_section_rarity-left">
                        <h6 className="graph_section_text">
                          Market Distribution Percentage
                        </h6>
                        <div className="graph_section_chartdata">
                          <div className="updatedchart">
                            <MarketDistribution
                              graph_texts={tooltip_text}
                              labelValue={labelValue}
                              labelName={labelName}
                              loading={false}
                            />
                          </div>
                          <div>
                            <div className="divider"></div>

                            <h1 className="update-text">
                              The Fancy Color Research Foundation
                            </h1>
                          </div>
                        </div>
                      </div>

                      <div className="graph_section_rarity-right graph_section_rarity-left">
                        <h6 className="graph_section_text">
                          Denomination Brackets
                        </h6>
                        <div className="market-distribution-top">
                          <div className="market-distribution">
                            <div className="distribution-row">
                              <h1
                                className={`distribution-text tab-distribution-text ${
                                  reportData?.range_slider?.rarity_degree?.trim() ===
                                  "Remarkable"
                                    ? "remarkable-bold"
                                    : ""
                                }`}
                              >
                                <div className="tab-text-card">Once</div>
                                <div className="dotted-text"></div>
                                <div className="tab-text-card">every 25y</div>
                              </h1>
                              <span
                                className={`distribution-label ${
                                  reportData?.range_slider?.rarity_degree?.trim() ===
                                  "Remarkable"
                                    ? "remarkable-bold"
                                    : ""
                                }`}
                              >
                                Remarkable
                              </span>
                            </div>

                            <div className="distribution-row">
                              <h1
                                className={`distribution-text tab-distribution-text ${
                                  reportData?.range_slider?.rarity_degree?.trim() ===
                                  "Exceptional"
                                    ? "remarkable-bold"
                                    : ""
                                }`}
                              >
                                {" "}
                                <div className="tab-text-card">Once</div>
                                <div className="dotted-text"></div>
                                <div className="tab-text-card">
                                  every 13 - 24y
                                </div>{" "}
                              </h1>
                              <span
                                className={`distribution-label ${
                                  reportData?.range_slider?.rarity_degree?.trim() ===
                                  "Exceptional"
                                    ? "remarkable-bold"
                                    : ""
                                }`}
                              >
                                Exceptional
                              </span>
                            </div>

                            <div className="distribution-row">
                              <h1
                                className={`distribution-text tab-distribution-text ${
                                  reportData?.range_slider?.rarity_degree?.trim() ===
                                  "Extremely Rare"
                                    ? "remarkable-bold"
                                    : ""
                                }`}
                              >
                                <div className="tab-text-card">Once</div>
                                <div className="dotted-text"></div>
                                <div className="tab-text-card">
                                  every 1 - 12y
                                </div>
                              </h1>
                              <span
                                className={`distribution-label ${
                                  reportData?.range_slider?.rarity_degree?.trim() ===
                                  "Extremely Rare"
                                    ? "remarkable-bold"
                                    : ""
                                }`}
                              >
                                Extremely Rare
                              </span>
                            </div>

                            <div className="distribution-row">
                              <h1
                                className={`distribution-text tab-distribution-text ${
                                  reportData?.range_slider?.rarity_degree?.trim() ===
                                  "Very Rare"
                                    ? "remarkable-bold"
                                    : ""
                                }`}
                              >
                                <div className="tab-text-card">1-3</div>
                                <div className="dotted-text"></div>
                                <div className="tab-text-card">yearly</div>
                              </h1>
                              <span
                                className={`distribution-label ${
                                  reportData?.range_slider?.rarity_degree?.trim() ===
                                  "Very Rare"
                                    ? "remarkable-bold"
                                    : ""
                                }`}
                              >
                                Very Rare
                              </span>
                            </div>

                            <div className="distribution-row">
                              <h1
                                className={`distribution-text tab-distribution-text ${
                                  reportData?.range_slider?.rarity_degree?.trim() ===
                                  "Rare"
                                    ? "remarkable-bold"
                                    : ""
                                }`}
                              >
                                <div className="tab-text-card">3-10</div>
                                <div className="dotted-text"></div>
                                <div className="tab-text-card">yearly</div>
                              </h1>
                              <span
                                className={`distribution-label ${
                                  reportData?.range_slider?.rarity_degree?.trim() ===
                                  "Rare"
                                    ? "remarkable-bold"
                                    : ""
                                }`}
                              >
                                Rare
                              </span>
                            </div>

                            <div className="distribution-row">
                              <h1
                                className={`distribution-text tab-distribution-text ${
                                  reportData?.range_slider?.rarity_degree?.trim() ===
                                  "Extraordinary"
                                    ? "remarkable-bold"
                                    : ""
                                }`}
                              >
                                <div className="tab-text-card">10-40</div>
                                <div className="dotted-text"></div>
                                <div className="tab-text-card">yearly</div>
                              </h1>
                              <span
                                className={`distribution-label ${
                                  reportData?.range_slider?.rarity_degree?.trim() ===
                                  "Extraordinary"
                                    ? "remarkable-bold"
                                    : ""
                                }`}
                              >
                                Extraordinary
                              </span>
                            </div>

                            <div className="distribution-row">
                              <h1
                                className={`distribution-text tab-distribution-text ${
                                  reportData?.range_slider?.rarity_degree?.trim() ===
                                  "Very Special"
                                    ? "remarkable-bold"
                                    : ""
                                }`}
                              >
                                <div className="tab-text-card">40-180</div>
                                <div className="dotted-text"></div>
                                <div className="tab-text-card">yearly</div>
                              </h1>
                              <span
                                className={`distribution-label ${
                                  reportData?.range_slider?.rarity_degree?.trim() ===
                                  "Very Special"
                                    ? "remarkable-bold"
                                    : ""
                                }`}
                              >
                                Very Special
                              </span>
                            </div>

                            <div className="distribution-row">
                              <h1
                                className={`distribution-text tab-distribution-text ${
                                  reportData?.range_slider?.rarity_degree?.trim() ===
                                  "Special"
                                    ? "remarkable-bold"
                                    : ""
                                }`}
                              >
                                <div className="tab-text-card">180-400</div>
                                <div className="dotted-text"></div>
                                <div className="tab-text-card">yearly</div>
                              </h1>
                              <span
                                className={`distribution-label ${
                                  reportData?.range_slider?.rarity_degree?.trim() ===
                                  "Special"
                                    ? "remarkable-bold"
                                    : ""
                                }`}
                              >
                                Special
                              </span>
                            </div>

                            <div className="distribution-row">
                              <h1
                                className={`distribution-text tab-distribution-text ${
                                  reportData?.range_slider?.rarity_degree?.trim() ===
                                  "Outstanding"
                                    ? "remarkable-bold"
                                    : ""
                                }`}
                              >
                                <div className="tab-text-card">400-900</div>
                                <div className="dotted-text"></div>
                                <div className="tab-text-card">yearly</div>
                              </h1>
                              <span
                                className={`distribution-label ${
                                  reportData?.range_slider?.rarity_degree?.trim() ===
                                  "Outstanding"
                                    ? "remarkable-bold"
                                    : ""
                                }`}
                              >
                                Outstanding
                              </span>
                            </div>

                            <div className="distribution-row">
                              <h1
                                className={`distribution-text tab-distribution-text ${
                                  reportData?.range_slider?.rarity_degree?.trim() ===
                                  "Notable"
                                    ? "remarkable-bold"
                                    : ""
                                }`}
                              >
                                <div className="tab-text-card">900-10,000</div>
                                <div className="dotted-text"></div>
                                <div className="tab-text-card">yearly</div>
                              </h1>
                              <span
                                className={`distribution-label ${
                                  reportData?.range_slider?.rarity_degree?.trim() ===
                                  "Notable"
                                    ? "remarkable-bold"
                                    : ""
                                }`}
                              >
                                Notable
                              </span>
                            </div>
                          </div>
                          <div className="divider-mt">
                            <div className="divider"></div>
                            <h1 className="update-text">
                              Last data update on{" "}
                              {formatDate(reportData?.textSheetData?.H12)}
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                    {!(custom_branding === "1" && companyLogo) ? (
                       <div className="rarity_report_top update-rarity-bottom">
                       <p>
                         The data acquired from The Fancy Color Diamond Rarity
                         Report©. may change from time to time and is not a
                         guarantee nor a reflection of the diamond’s value. For
                         additional information regarding the data, and other
                         limitations, contact us at:{" "}
                         <a href="mailto:customers@fcresearch.org">
                           customers@fcresearch.org
                         </a>
                       </p>
                     </div>  
                    ) :
                    (
                      <div className="share-rarity-bottom">
                        <div className="share-rarity-bottom-text update-rarity-bottom"> 
                        <p>
                         The data acquired from The Fancy Color Diamond Rarity
                         Report©. may change from time to time and is not a
                         guarantee nor a reflection of the diamond’s value. For
                         additional information regarding the data, and other
                         limitations, contact us at:{" "}
                         <a href="mailto:customers@fcresearch.org">
                           customers@fcresearch.org
                         </a>
                       </p>
                        </div>
                        <div className="share-rarity-bottom-logo">
                        <img
                              src={companyLogo}
                              alt="Bottom Logo"
                            />
                        </div>
                       
                      
                    </div>
                    )}
                    
                   
                  </div>
                </div>
                <div className="rarity-tool-grid old-rarity-report rarity-mob-updated">
                <div className="rarity-tool-left ">
                    <div>
                      <div className="rarity-heading-wrapper card-pd rarity-page-header">
                        <div className="rarity_report_top_heading ">
                         
                          <h1 className="relative rarity_report_head_top ">the rarity report

                          </h1>
                          <span>
                            <CIcon />
                            </span>
                        </div>
                      </div>
                      <div className="rarity-right-img-data updated-mob-rarity">
                        <p className="report-date m-0">
                          {formatDateWebReport(reportData?.timestamp)}
                        </p>
                        <p className="report-number m-0">
                          REPORT {reportData?.postId}
                        </p>
                      </div>
                      <div className="divider update-divider"></div>
                      <div className="text-center">
                        <img
                              src={companyLogo}
                              alt="Logo"
                              className="rarity-share-logo"
                            />
                        </div>
                      <div className={`rarity-note update-rarity-note mt-0 ${!(report_number ===""|| !report_number) && "rarity-note-for-gia"}`}>
                        Rarity data refers to a natural color diamond with the
                        described gemological properties
                      </div>

                      <div className="rarity_report_gemological_properties">
                        <p>
                          {parseFloat(reportData?.data?.weight).toFixed(2)} ct,{" "}
                          {reportData?.data?.saturation},{" "}
                          {reportData?.data?.color === "Chameleon" &&
                          reportData?.data?.report_number
                            ? `${reportData?.data?.colorName} - Chameleon`
                            : !reportData?.data?.report_number
                            ? reportData?.data?.color
                            : reportData?.data?.colorName}
                          ,{" "}
                          {reportData?.data?.report_number === "" ||
                          !reportData?.data?.report_number
                            ? reportData?.data?.shape
                            : reportData?.range_slider?.shapeName}
                          ,{" "}
                          {reportData?.data?.report_number === "" ||
                          !reportData?.data?.report_number
                            ? reportData?.data?.clarity
                            : reportData?.data?.giaclarity}{" "}
                        </p>
                        {reportData?.data?.report_number && (
                          <span>GIA No. {reportData?.data?.report_number}</span>
                        )}
                      </div>
                    </div>

                    <div className="grade-mob-view">
                      <div className="rarity-highlighted-box highlight-box1">
                        <div className="text-block ">
                          <h6 className="main-text">rarity</h6>
                          <p className="highlight-text">
                            ONE IN {highlightText}
                          </p>
                        </div>
                      </div>

                      <div className="rarity-highlighted-box highlight-box1 highlight-box2">
                        <div className="text-block ">
                          <h6 className="main-text">market entry</h6>

                          <p className="highlight-text">
                            {reportData?.bubble_texts?.H18?.split("#")[0]}
                          </p>
                          <p className="highlight-text">
                            {reportData?.bubble_texts?.H18?.split("#")[1]}
                          </p>

                        </div>
                        {!showContent && (
                          <p
                            className="text-decoration-underline learn-about-text"
                            onClick={() => setShowContent(!showContent)}
                          >
                            learn about the rarity levels
                          </p>
                        )}

                        {
                          <div
                            className={`graph_section_rarity-right graph_section_rarity-left ${
                              showContent
                                ? "rarity-level-text-animation"
                                : "collapsed"
                            }`}
                          >
                            <h6 className="graph_section_text">
                              Denomination Brackets
                            </h6>
                            <div className="market-distribution-top">
                              <div className="market-distribution">
                                <div className="distribution-row">
                                  <h1
                                    className={`distribution-text tab-distribution-text ${
                                      reportData?.range_slider?.rarity_degree?.trim() ===
                                      "Remarkable"
                                        ? "remarkable-bold"
                                        : ""
                                    }`}
                                  >
                                    <div className="tab-text-card">Once</div>
                                    <div className="dotted-text"></div>
                                    <div className="tab-text-card">
                                      every 25y
                                    </div>
                                  </h1>
                                  <span
                                    className={`distribution-label ${
                                      reportData?.range_slider?.rarity_degree?.trim() ===
                                      "Remarkable"
                                        ? "remarkable-bold"
                                        : ""
                                    }`}
                                  >
                                    Remarkable
                                  </span>
                                </div>

                                <div className="distribution-row">
                                  <h1
                                    className={`distribution-text tab-distribution-text ${
                                      reportData?.range_slider?.rarity_degree?.trim() ===
                                      "Exceptional"
                                        ? "remarkable-bold"
                                        : ""
                                    }`}
                                  >
                                    {" "}
                                    <div className="tab-text-card">Once</div>
                                    <div className="dotted-text"></div>
                                    <div className="tab-text-card">
                                      every 13 - 24y
                                    </div>{" "}
                                  </h1>
                                  <span
                                    className={`distribution-label ${
                                      reportData?.range_slider?.rarity_degree?.trim() ===
                                      "Exceptional"
                                        ? "remarkable-bold"
                                        : ""
                                    }`}
                                  >
                                    Exceptional
                                  </span>
                                </div>

                                <div className="distribution-row">
                                  <h1
                                    className={`distribution-text tab-distribution-text ${
                                      reportData?.range_slider?.rarity_degree?.trim() ===
                                      "Extremely Rare"
                                        ? "remarkable-bold"
                                        : ""
                                    }`}
                                  >
                                    <div className="tab-text-card">Once</div>
                                    <div className="dotted-text"></div>
                                    <div className="tab-text-card">
                                      every 1 - 12y
                                    </div>
                                  </h1>
                                  <span
                                    className={`distribution-label ${
                                      reportData?.range_slider?.rarity_degree?.trim() ===
                                      "Extremely Rare"
                                        ? "remarkable-bold"
                                        : ""
                                    }`}
                                  >
                                    Extremely Rare
                                  </span>
                                </div>

                                <div className="distribution-row">
                                  <h1
                                    className={`distribution-text tab-distribution-text ${
                                      reportData?.range_slider?.rarity_degree?.trim() ===
                                      "Very Rare"
                                        ? "remarkable-bold"
                                        : ""
                                    }`}
                                  >
                                    <div className="tab-text-card">1-3</div>
                                    <div className="dotted-text"></div>
                                    <div className="tab-text-card">yearly</div>
                                  </h1>
                                  <span
                                    className={`distribution-label ${
                                      reportData?.range_slider?.rarity_degree?.trim() ===
                                      "Very Rare"
                                        ? "remarkable-bold"
                                        : ""
                                    }`}
                                  >
                                    Very Rare
                                  </span>
                                </div>

                                <div className="distribution-row">
                                  <h1
                                    className={`distribution-text tab-distribution-text ${
                                      reportData?.range_slider?.rarity_degree?.trim() ===
                                      "Rare"
                                        ? "remarkable-bold"
                                        : ""
                                    }`}
                                  >
                                    <div className="tab-text-card">3-10</div>
                                    <div className="dotted-text"></div>
                                    <div className="tab-text-card">yearly</div>
                                  </h1>
                                  <span
                                    className={`distribution-label ${
                                      reportData?.range_slider?.rarity_degree?.trim() ===
                                      "Rare"
                                        ? "remarkable-bold"
                                        : ""
                                    }`}
                                  >
                                    Rare
                                  </span>
                                </div>

                                <div className="distribution-row">
                                  <h1
                                    className={`distribution-text tab-distribution-text ${
                                      reportData?.range_slider?.rarity_degree?.trim() ===
                                      "Extraordinary"
                                        ? "remarkable-bold"
                                        : ""
                                    }`}
                                  >
                                    <div className="tab-text-card">10-40</div>
                                    <div className="dotted-text"></div>
                                    <div className="tab-text-card">yearly</div>
                                  </h1>
                                  <span
                                    className={`distribution-label ${
                                      reportData?.range_slider?.rarity_degree?.trim() ===
                                      "Extraordinary"
                                        ? "remarkable-bold"
                                        : ""
                                    }`}
                                  >
                                    Extraordinary
                                  </span>
                                </div>

                                <div className="distribution-row">
                                  <h1
                                    className={`distribution-text tab-distribution-text ${
                                      reportData?.range_slider?.rarity_degree?.trim() ===
                                      "Very Special"
                                        ? "remarkable-bold"
                                        : ""
                                    }`}
                                  >
                                    <div className="tab-text-card">40-180</div>
                                    <div className="dotted-text"></div>
                                    <div className="tab-text-card">yearly</div>
                                  </h1>
                                  <span
                                    className={`distribution-label ${
                                      reportData?.range_slider?.rarity_degree?.trim() ===
                                      "Very Special"
                                        ? "remarkable-bold"
                                        : ""
                                    }`}
                                  >
                                    Very Special
                                  </span>
                                </div>

                                <div className="distribution-row">
                                  <h1
                                    className={`distribution-text tab-distribution-text ${
                                      reportData?.range_slider?.rarity_degree?.trim() ===
                                      "Special"
                                        ? "remarkable-bold"
                                        : ""
                                    }`}
                                  >
                                    <div className="tab-text-card">180-400</div>
                                    <div className="dotted-text"></div>
                                    <div className="tab-text-card">yearly</div>
                                  </h1>
                                  <span
                                    className={`distribution-label ${
                                      reportData?.range_slider?.rarity_degree?.trim() ===
                                      "Special"
                                        ? "remarkable-bold"
                                        : ""
                                    }`}
                                  >
                                    Special
                                  </span>
                                </div>

                                <div className="distribution-row">
                                  <h1
                                    className={`distribution-text tab-distribution-text ${
                                      reportData?.range_slider?.rarity_degree?.trim() ===
                                      "Outstanding"
                                        ? "remarkable-bold"
                                        : ""
                                    }`}
                                  >
                                    <div className="tab-text-card">400-900</div>
                                    <div className="dotted-text"></div>
                                    <div className="tab-text-card">yearly</div>
                                  </h1>
                                  <span
                                    className={`distribution-label ${
                                      reportData?.range_slider?.rarity_degree?.trim() ===
                                      "Outstanding"
                                        ? "remarkable-bold"
                                        : ""
                                    }`}
                                  >
                                    Outstanding
                                  </span>
                                </div>

                                <div className="distribution-row">
                                  <h1
                                    className={`distribution-text tab-distribution-text ${
                                      reportData?.range_slider?.rarity_degree?.trim() ===
                                      "Notable"
                                        ? "remarkable-bold"
                                        : ""
                                    }`}
                                  >
                                    <div className="tab-text-card">
                                      900-10,000
                                    </div>
                                    <div className="dotted-text"></div>
                                    <div className="tab-text-card">yearly</div>
                                  </h1>
                                  <span
                                    className={`distribution-label ${
                                      reportData?.range_slider?.rarity_degree?.trim() ===
                                      "Notable"
                                        ? "remarkable-bold"
                                        : ""
                                    }`}
                                  >
                                    Notable
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        }

                        </div>

                    </div>

                    <div className="card-pd card-height">
                      <GemologicalCharacteristics
                        graph_texts={tooltip_text}
                        labelValue={labelValue}
                        labelName={labelName}
                        loading={false}
                        section={"rarity"}
                      />
                    </div>


                  </div>


                  <div className="rarity-tool-right ">
                    <div className="rarity-time-report-no-wrapper grade-desktop-view">
                      <h5>{formatDateReport(reportData?.timestamp)}</h5>
                      <p>Report {reportData?.postId}</p>
                    </div>
                    <div className="grade-desktop-view cardHightRight1"  >
                      <RarityToolCard
                        cardDesc={
                          "Fancy Color Diamond appreciation revolves around beauty and rarity. The Rarity Report illustrates the market prevalence of a polished diamond. Whether a diamond is one of several hundred or is introduced to the market once a decade, rarity was, and always will be, a part of the epic story of a fancy color diamond."
                        }
                        loading={false}
                        line={2}
                        className="rarity-left-content-alignment"
                      />
                    </div>
                    <div className={`grade-desktop-view ${characteristics.report_number ? "share-rarity-grade" : "right_grade"}`}>
                      <RarityHighlightedBox
                        range_slider={range_slider}
                        cardDesc={bubble_texts?.H18}
                        animationText={animationText}
                        loading={loading}
                      />
                    </div>

                    <RarityInfo range_slider={range_slider} />

                  </div>
                </div>
                <div>
                      <div className="rarity-tool-bottom-text-padding1">

                        <p className="rarity-tool-text mb-2">
                          The data acquired from The Fancy Color Diamond Rarity Report©. may change from time to time and is not a guarantee nor a reflection of the diamond’s value. For additional information regarding the data, and other limitations, contact us at: customers@fcresearch.org
                        </p>



                        <p className="report-timestamp   rarity-report-time-stamp grade-mob-view">


                          <span className="update-text last-date"> Last data update on {formatDate(reportData?.textSheetData?.H12 || reportData?.range_slider?.textSheetData?.H12)}</span>
                        </p>

                      </div>
                    </div>

              <div className="report-action-footer ">
                <div className="rarity-header-section grade-mob-view"></div>
              </div>

              <ScrollToTop scrollClass={"scroll-up-share"}/>

             
            </div>
          </div>
        </div>
      </div>
    </>
  );
};



export default ShareRarityReport;

const CIcon = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.90008 7.9975C10.9667 8.05 11.2417 9.00375 11.2584 9.45H12.7501C12.6834 7.7175 11.5084 6.65875 9.87508 6.65875C8.03342 6.65875 6.66675 7.875 6.66675 10.6225C6.66675 12.32 7.44175 14.3325 9.86675 14.3325C11.7167 14.3325 12.7084 12.8888 12.7334 11.7513H11.2417C11.2167 12.2675 10.8667 12.9587 9.88342 13.0112C8.79175 12.9762 8.33342 12.0838 8.33342 10.6225C8.33342 8.09375 9.40008 8.015 9.90008 7.9975ZM10.0001 1.75C5.40008 1.75 1.66675 5.67 1.66675 10.5C1.66675 15.33 5.40008 19.25 10.0001 19.25C14.6001 19.25 18.3334 15.33 18.3334 10.5C18.3334 5.67 14.6001 1.75 10.0001 1.75ZM10.0001 17.5C6.32508 17.5 3.33341 14.3587 3.33341 10.5C3.33341 6.64125 6.32508 3.5 10.0001 3.5C13.6751 3.5 16.6667 6.64125 16.6667 10.5C16.6667 14.3587 13.6751 17.5 10.0001 17.5Z" fill="black"/>
    </svg>

  );
};





